import React, { useState, useEffect } from 'react';

import { GmapsBusiness } from './GmapsBusiness';
import { BarCodeReader } from './BarCodeReader';

import "./Registry.css"

export const Registry = () => {
  const [barcode, setBarcode] = useState('');
  const [nearbyStores, setNearbyStores] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [otherStore, setOtherStore] = useState('');
  const [otherStoreName, setOtherStoreName] = useState('');
  const [otherOptSelected, setOtherOptSelected] = useState(false);
  const [otherStoreAddress, setOtherStoreAddress] = useState(null);
  const [mapShow, setMapShow] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Get current location
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);
  
  const handleOtherStore = (event) => {
    event.preventDefault();
    let data = {
      name: otherStoreName,
      location: currentLocation,
      address: otherStoreAddress,
    }
    // console.log('Selected store (event.target.store.value):', event.target.store.value);
    console.log('Selected store (otherStoreName):', otherStoreName);
    console.log('Selected other store object (data):', data);
    setOtherStore(data);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit form data (barcode, selected store or other store)
    console.log('Barcode:', barcode);
    console.log('Selected store:', event.target.store.value);
    if (event.target.store.value === 'other') {
      console.log('Other store:', otherStore);
    }
  };
  
  const handleMapShow = (event) => {
    event.preventDefault();
    setMapShow(!mapShow);
  };

  if (error) {
    return (
        <div>
            <h2>Error</h2>
            <p>{error}</p>
        </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='barcode-reader-section'>
        <BarCodeReader
          setBarcodeExternal={setBarcode}
          setExternalError={setError}
      />
      </div>
      <div className='store-section'>
        <label>
          Store:
          <select
            name="store"
            onChange={(e) => setOtherOptSelected(e.target.value === 'other')}
          >
            <option value="">Select a store</option>
            {nearbyStores && nearbyStores.map((store) => (
              <option key={store.name} value={store.name}>
                {store.name}
              </option>
            ))}
            <option value="other">Other</option>
          </select>
        </label>
      </div>
      <div className='new-store-section'>
        {otherOptSelected && (
          <>
            <label>
              Other Store:
              <input
                type="text"
                name="otherStoreName"
                value={otherStoreName}
                onChange={(e) => setOtherStoreName(e.target.value)}
              />
            </label>
            <button
              type="button"
              onClick={handleOtherStore}
            >
              Done
            </button>
            <div>
              {otherStore && (
                <p>
                  Selected Store:
                  {' '}
                  {`Nane: ${otherStore.name} | Address: ${otherStore.address} | Location: ${otherStore.location.lat}, ${otherStore.location.lng}`}
                </p>
              )}
            </div>
          </>
        )}
      </div>
      <div className='buttons-section'>
        <button type="submit">Submit</button>
      </div>
      <div className='map-section'>
        <div className='map-button'>
          <button
            type="button"
            onClick={handleMapShow}
          >
            {mapShow ? "Hide Map" : "Show Map"}
          </button>
        </div>
        <div className='map-main-container'>
          {mapShow && (
            <GmapsBusiness
              setNearbyStoresExternal={setNearbyStores}
              setAddressExternal={setOtherStoreAddress}
              setExternalError={setError}
            />
          )}
        </div>
      </div>
    </form>
  );
};
