import React, { useEffect, useState } from 'react';

import * as gs from "genericsuite";

import { initMap, loadGoogleMapsScript } from './GoogleMapsNeighborhoodDiscovery';
import "./GmapsBusiness.css"

const WaitAnimation = gs.waitAnimationUtility.WaitAnimation;

export const GmapsBusiness = ({
    setNearbyStoresExternal,
    setAddressExternal,
    setExternalError,
}) => {
    // const mapRef = useRef(null);
    // const [map, setMap] = useState(null);
    // const [places, setPlaces] = useState([]);
    // const [selectedPlaceId, setSelectedPlaceId] = useState(null);
    // const [showMoreButtonDisabled, setShowMoreButtonDisabled] = useState(false);
    // const [nextPlaceIndex, setNextPlaceIndex] = useState(0);

    const [nearbyStores, setNearbyStores] = useState([]);
    const [address, setAddress] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('Calling loadGoogleMapsScript...');
        loadGoogleMapsScript(process.env.REACT_APP_GOOGLE_MAPS_API_KEY, () => {
            console.log('Initializing map...');
            initMap(setNearbyStores, setAddress, setError);
            console.log('Map initialization finished');
            setLoading(false);
        });
        console.log('Calling loadGoogleMapsScript finished');
    }, []);


    // ------ Additional component logic here...

    useEffect(() => {
        // Fetch nearby businesses from Google Maps API
        if (nearbyStores) {
            const stores = nearbyStores.map((result) => ({
                name: result.name,
                location: result.geometry.location,
                address: result.vicinity,
                city: '', // You may need to parse this from the address
                country: '', // You may need to parse this from the address
                zipCode: '', // You may need to parse this from the address
            }));
            setNearbyStoresExternal(stores);
        }
    }, [nearbyStores, setNearbyStoresExternal]);

    useEffect(() => {
        // Fetch nearby businesses from Google Maps API
        if (address) {
            setAddressExternal(address);
        }
    }, [address, setAddressExternal]);

    useEffect(() => {
        setExternalError(error)
    }, [error, setExternalError]);

    // ------ Additional component logic here...

    // To include these scripts and styles in a React component, you should modify the public/index.html file or use React Helmet for dynamic changes.
    // Example using React Helmet:
    // const HeaderScriptsAndStyles = () => (
    //     <Helmet>
    //         <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
    //         <script src="https://ajax.googleapis.com/ajax/libs/handlebars/4.7.7/handlebars.min.js"></script>
    //         <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet"/>
    //         <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
    //     </Helmet>
    // );

    if (error) {
        return '';
    }

    if (loading) {
        return (
            <div>
                <WaitAnimation />
            </div>
        );
    }

    // Note: The <script> tag for the Handlebars template cannot be directly included via Helmet and should be managed within component logic or HTML.
    return (
        <div className='map-container'>
            <div className="neighborhood-discovery">
                <div className="places-panel panel no-scroll">
                    <header className="navbar">
                        <div className="search-input">
                            <input id="placeSearchInput" className="place-search-input" placeholder="Search nearby places" />
                            <button className="place-search-button">
                                <img src="https://fonts.gstatic.com/s/i/googlematerialicons/search/v11/24px.svg" alt="search" />
                            </button>
                        </div>
                    </header>
                    <div className="results">
                        <ul className="place-results-list"></ul>
                    </div>
                    <button className="show-more-button sticky">
                        <span>Show More</span>
                        <img className="right" src="https://fonts.gstatic.com/s/i/googlematerialicons/expand_more/v11/24px.svg" alt="expand" />
                    </button>
                </div>

                {/* <div ref={(el) => this.uE = el}></div> */}

                <div className="details-panel panel"></div>
                <div className="map"></div>
                <div className="photo-modal">
                    <img alt="place photo" />
                    <div>
                        <button className="back-button">
                            <img className="icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/arrow_back/v11/24px.svg" alt="back" />
                        </button>
                        <div className="photo-text">
                            <div className="photo-place"></div>
                            <div className="photo-attrs">Photo by <span></span></div>
                        </div>
                    </div>
                </div>
                <svg className="marker-pin" xmlns="http://www.w3.org/2000/svg" width="26" height="38" fill="none">
                    <path d="M13 0C5.817 0 0 5.93 0 13.267c0 7.862 5.59 10.81 9.555 17.624C12.09 35.248 11.342 38 13 38c1.723 0 .975-2.817 3.445-7.043C20.085 24.503 26 21.162 26 13.267 26 5.93 20.183 0 13 0Z" />
                </svg>
            </div>
        </div>
    );
};

// export default GmapsBusiness;
