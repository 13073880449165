import React from 'react';

import * as gsAi from "genericsuite-ai";

import { HomePage } from '../HomePage/HomePage.jsx';
import { AboutBody } from '../About/About.jsx';

import { Registry } from '../Registry/Registry.jsx';

// import { ExampleMainElement } from '../ExampleMenu/ExampleMainElement.jsx';
// import { ExampleChildElement } from '../ExampleMenu/ExampleChildElement.jsx';

const componentMap = {
    "AboutBody": AboutBody,
    "HomePage": HomePage,
    // "ExampleMainElement": ExampleMainElement,
    // "ExampleChildElement": ExampleChildElement,
    "Registry": Registry,
};

export const App = () => {
    return (
        <gsAi.App
            // appLogo="prico_logo_1000.svg"
            // appLogo="prico_logo_square.svg"
            appLogo="prico_logo_horizontal.svg"
            componentMap={componentMap}
        />
    );
}