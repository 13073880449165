import React, { useState, useEffect, useRef } from 'react';
import Quagga from 'quagga';

import * as gs from "genericsuite";

import "./BarCodeReader.css";
import { ProductData } from './ProductData';

const mediaDirectory = gs.generalConstants.imageDirectory;
const WaitAnimation = gs.waitAnimationUtility.WaitAnimation;

export const BarCodeReader = ({
    setBarcodeExternal,
    setExternalError,
}) => {
    const [barcode, setBarcode] = useState('');
    const videoRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        return () => {
            // Clean up Quagga when the component unmounts
            console.log('Cleaning up Quagga...');
            if (!error) {
                try {
                    Quagga.stop();
                } catch(err) {
                    console.error('Error cleaning up Quagga', err);
                }
            }
            if (videoRef.current && videoRef.current.srcObject) {
                console.log('Turning off camera...');
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => track.stop());
                videoRef.current.srcObject = null;
            }
            console.log('Clean up finished');
        };
    }, []);

    const playSound = () => {
        // https://www.soundjay.com/beep-sounds-1.html#google_vignette
        const audio = new Audio(`${mediaDirectory}beep-07a.wav`);
        audio.play();        
    }

    useEffect(() => {
        setBarcodeExternal(barcode)
    }, [barcode, setBarcodeExternal]);

    useEffect(() => {
        setExternalError(error)
    }, [error, setExternalError]);

    // const initCameraSelection = () => {
    //     var streamLabel = Quagga.CameraAccess.getActiveStreamLabel();
    //     return Quagga.CameraAccess.enumerateVideoDevices()
    //         .then(function (devices) {
    //             function pruneText(text) {
    //                 return text.length > 30 ? text.substr(0, 30) : text;
    //             }
    //             var $deviceSelection = document.getElementById("deviceSelection");
    //             while ($deviceSelection.firstChild) {
    //                 $deviceSelection.removeChild($deviceSelection.firstChild);
    //             }
    //             devices.forEach(function (device) {
    //                 var $option = document.createElement("option");
    //                 $option.value = device.deviceId || device.id;
    //                 $option.appendChild(document.createTextNode(pruneText(device.label || device.deviceId || device.id)));
    //                 $option.selected = streamLabel === device.label;
    //                 $deviceSelection.appendChild($option);
    //             });
    //         });
    // }

    useEffect(() => {
        if (Quagga && !Quagga.initialized) {
            console.log('Init Quagga...');
            Quagga.initialized = true;
            // Initialize Quagga barcode reader
            try {
                Quagga.init({
                    inputStream: {
                        name: 'Live',
                        type: 'LiveStream',
                        target: document.querySelector('#barcode-reader'),
                        // target: document.querySelector('#barcode-reader'),
                        // target: document.getElementById('barcode-reader'),
                        constraints: {
                            facingMode: 'environment', // Use the rear camera
                        },
                    },
                    locate: true,
                    locator: {
                        patchSize: "medium",
                        halfSample: true
                    },
                    numOfWorkers: 2,
                    frequency: 10,
                    decoder: {
                        readers: [
                            "ean_reader",
                            {
                                // ean_reader_extended
                                format: "ean_reader",
                                config: {
                                    supplements: [
                                        'ean_5_reader', 'ean_2_reader'
                                    ]
                                }
                            },
                            "ean_8_reader", 
                            "code_128_reader", 
                            "code_39_reader", 
                            "code_39_vin_reader", 
                            "upc_reader", 
                            "upc_e_reader", 
                            "codabar_reader", 
                            "i2of5_reader", 
                            "2of5_reader", 
                            "code_93_reader", 
                        ],
                        debug: {
                            drawBoundingBox: true,
                            showFrequency: true,
                            drawScanline: true,
                            showPattern: true,
                        },
                    }
                }, (err) => {
                    if (err) {
                        console.error('Quagga ERROR:', err);
                        setError('Failed to initialize barcode reader.');
                        return;
                    }
                    // console.log('Starting initCameraSelection...');
                    // initCameraSelection();
                    console.log('Starting Quagga...');
                    Quagga.start();
                    console.log('Quagga started');
                    setLoading(false);
                });
            } catch(err) {
                console.error('Error initializing Quagga', err);
                setError('Failed to initialize barcode reader.');
            }

            // Request camera access
            try {
                navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    // Camera access granted
                    videoRef.current.srcObject = stream
                })
                .catch((err) => {
                    console.error('Failed to access camera [1]:', err);
                    // setError('Failed to access camera.');
                });
            } catch (err) {
                console.error('Failed to access camera [2]:', err);
                setError('Failed to access camera. Probably you are using a insecure (http) connection. Please try again using https.');
            }

            // Listen for errors

            Quagga.onProcessed(function (result) {
                var drawingCtx = Quagga.canvas.ctx.overlay,
                    drawingCanvas = Quagga.canvas.dom.overlay;

                if (result) {
                    if (result.boxes) {
                        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                        result.boxes.filter(function (box) {
                            return box !== result.box;
                        }).forEach(function (box) {
                            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                        });
                    }

                    if (result.box) {
                        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
                    }

                    if (result.codeResult && result.codeResult.code) {
                        console.log('onProcessed | Barcode detected:', result);
                        Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
                    }
                }
            });

            // Listen for barcode detection
            Quagga.onDetected((data) => {
                console.log('onDetected | Barcode detected:', data.codeResult.code);
                console.log('onDetected | complete data:', data);
                playSound();
                setBarcode(data.codeResult.code);
            });
        }
    }, []);

    return (
        <div className="barcode-reader-container">
            <div id="barcode-reader" className="viewport"></div>
            {error && (
                <div>
                    {error}
                </div>
            )}
            {loading && !error && (
                <div>
                    <WaitAnimation />
                </div>
            )}
            {!loading && !error && (
                <div className='barcode-reader-details'>
                    <label>
                        Barcode:<br/>
                        <input
                            type="text"
                            name="barcode"
                            value={barcode}
                            // readOnly
                            onChange={(e) => setBarcode(e.target.value)}
                        />
                    </label>
                    <ProductData
                        productCode={barcode}
                    />
                </div>
            )}
        </div>
    );
};
