import React from 'react'

import * as gs from "genericsuite";

const GsAboutBody = gs.AboutBody;
const console_debug_log = gs.loggingService.console_debug_log;

const debug = false;

export const AboutBody = ({ children }) => {
    if (debug) console_debug_log('>>>> Prico AboutBody <<<<');
    return (
        <GsAboutBody>
            <>
                <p>
Does it ever happen that you can't recall where the most affordable places are to buy food, medicines, and other items?
                </p>
                <p>
<b>Prico</b> is a practical and easy-to-use tool to help you know where to get the best prices on things.
                </p>
                <p>
The motivation was our own experience with weekly grocery shopping. Normally we are too lazy to look up past receipts to compare prices and know which supermarket to buy certain things from in order to save money. The same goes for medicines, appliances, and other general purchases.
                </p>
                <p>
That's how <b>Prico</b> was born: an app that allows you to record product prices at different stores using mobile devices, with the helpful feature of scanning barcodes to make data entry easier.
                </p>
                <p>
                    2024-04-12 | Carlos J. Ramirez
                </p>
                {children}
            </>
        </GsAboutBody>
    )
}
