import React, { useState, useEffect } from 'react';

import * as gs from "genericsuite";

import "./ProductData.css";

export const ProductData = ({
    productCode,
}) => {
    const [productName, setProductName] = useState('');

    const handleCodeChange = (event) => {
        event.preventDefault();    
    }

    return (
        <div className='product-section'>
            <label>
                Product:
                <input
                    type="text"
                    name="productName"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                />
            </label>
            <label>
                Product Code:
                <input
                    type="text"
                    name="productCode"
                    value={productCode}
                    readOnly
                />
            </label>
        </div>
    );
}

