// Neighborhood Discovery
// https://console.cloud.google.com/google/maps-apis/build/neighborhood-discovery
// 2024-04-30 | CR

// ------ Google Maps example JS scripts

// import Handlebars from 'handlebars/dist/handlebars.min.js'; // Assuming Handlebars is installed via npm
import Handlebars from "handlebars";

let gMapsConfiguration;

/* global google */
// This line above is a directive for ESLint, telling it that google is a global variable that will be defined elsewhere, 
// typically by including the Google Maps API script in your HTML.

const DEBUG = true;

const CONFIGURATION = {
    "capabilities": {
        "search": true,
        "distances": false,
        "directions": false,
        "contacts": true,
        "atmospheres": true,
        "thumbnails": true,
    },
    "pois": [
        // { "placeId": "ChIJTbrSV22BRo4ReY8XpKxlu2c" },
        // Add the rest of the place IDs here...
    ],
    "mapRadius": 2000, // 2 km radius
    "mapOptions": {
        "center": {
            "lat": 0,
            "lng": 0
        },
        "fullscreenControl": true,
        "mapTypeControl": true,
        "streetViewControl": false,
        "zoom": 16,
        "zoomControl": true,
        "maxZoom": 20,
        "mapId": "",
    },
    "mapsApiKey": process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    "businessTypeFilter": [
        'store',
        'supermarket',
        'department_store',
        'shopping_mall',
        // 'restaurant',
        // 'cafe',
        // 'bar',
    ],
};

// Documentation Reference: Handlebars
// https://handlebarsjs.com/guide/builtin-helpers.html#each

const resultsTemplateInnerHTML = `
{{#each places}}
<li class="place-result">
<div class="text">
    <button class="name">{{name}}</button>
    <div class="info">
        {{#if rating}}
        <span>{{rating}}</span>
        <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star/v15/24px.svg" alt="rating" class="star-icon" />
        {{/if}}
        {{#if numReviews}}
        <span>&nbsp;({{numReviews}})</span>
        {{/if}}
        {{#if priceLevel}}
        &#183;&nbsp;<span>{{#each dollarSigns}}\${{/each}}&nbsp;</span>
        {{/if}}
    </div>
    <div class="info">{{type}}</div>
</div>
<button class="photo" style="background-image:url({{photos.0.urlSmall}})" aria-label="show photo in viewer"></button>
</li>
{{/each}}
`;

const detailsTemplateInnerHTML = `
<div class="navbar">
<button class="back-button">
    <img class="icon" src="https://fonts.gstatic.com/s/i/googlematerialicons/arrow_back/v11/24px.svg" alt="back" />
    Back
</button>
</div>
<header>
<h2>{{name}}</h2>
<div class="info">
    {{#if rating}}
    <span class="star-rating-numeric">{{rating}}</span>
    <span>
        {{#each fullStarIcons}}
        <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star/v15/24px.svg" alt="full star" class="star-icon" />
        {{/each}}
        {{#each halfStarIcons}}
        <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star_half/v17/24px.svg" alt="half star" class="star-icon" />
        {{/each}}
        {{#each emptyStarIcons}}
        <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star_outline/v9/24px.svg" alt="empty star" class="star-icon" />
        {{/each}}
    </span>
    {{/if}}
    {{#if numReviews}}
    <a href="{{url}}" target="_blank">{{numReviews}} reviews</a>
    {{else}}
    <a href="{{url}}" target="_blank">See on Google Maps</a>
    {{/if}}
    {{#if priceLevel}}
    &#183;
    <span class="price-dollars">
        {{#each dollarSigns}}\${{/each}}
    </span>
    {{/if}}
</div>
{{#if type}}
<div class="info">{{type}}</div>
{{/if}}
</header>
<div class="section">
{{#if address}}
<div class="contact">
    <img src="https://fonts.gstatic.com/s/i/googlematerialicons/place/v10/24px.svg" alt="Address" class="icon" />
    <div class="text">
        {{address}}
    </div>
</div>
{{/if}}
{{#if website}}
<div class="contact">
    <img src="https://fonts.gstatic.com/s/i/googlematerialicons/public/v10/24px.svg" alt="Website" class="icon" />
    <div class="text">
        <a href="{{website}}" target="_blank">{{websiteDomain}}</a>
    </div>
</div>
{{/if}}
{{#if phoneNumber}}
<div class="contact">
    <img src="https://fonts.gstatic.com/s/i/googlematerialicons/phone/v10/24px.svg" alt="Phone number" class="icon" />
    <div class="text">
        {{phoneNumber}}
    </div>
</div>
{{/if}}
{{#if openingHours}}
<div class="contact">
    <img src="https://fonts.gstatic.com/s/i/googlematerialicons/schedule/v12/24px.svg" alt="Opening hours" class="icon" />
    <div class="text">
        {{#each openingHours}}
        <div>
            <span class="weekday">{{days}}</span>
            <span class="hours">{{hours}}</span>
        </div>
        {{/each}}
    </div>
</div>
{{/if}}
</div>
{{#if photos}}
<div class="photos section">
{{#each photos}}
<button class="photo" style="background-image:url({{urlSmall}})" aria-label="show photo in viewer"></button>
{{/each}}
</div>
{{/if}}
{{#if reviews}}
<div class="reviews section">
<p class="attribution">Reviews by Google users</p>
{{#each reviews}}
<div class="review">
    <a class="reviewer-identity" href="{{author_url}}" target="_blank">
        <div class="reviewer-avatar" style="background-image:url({{profile_photo_url}})"></div>
        <div class="reviewer-name">{{author_name}}</div>
    </a>
    <div class="rating info">
        <span>
            {{#each fullStarIcons}}
            <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star/v15/24px.svg" alt="full star" class="star-icon" />
            {{/each}}
            {{#each halfStarIcons}}
            <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star_half/v17/24px.svg" alt="half star" class="star-icon" />
            {{/each}}
            {{#each emptyStarIcons}}
            <img src="https://fonts.gstatic.com/s/i/googlematerialicons/star_outline/v9/24px.svg" alt="empty star" class="star-icon" />
            {{/each}}
        </span>
        <span class="review-time">{{relative_time_description}}</span>
    </div>
    <div class="info">{{text}}</div>
</div>
{{/each}}
</div>
{{/if}}
{{#if html_attributions}}
<div class="section">
{{#each html_attributions}}
<p class="attribution">{{{this}}}</p>
{{/each}}
</div>
{{/if}}
`;

/** Hides a DOM element and optionally focuses on focusEl. */
function hideElement(el, focusEl) {
    el.style.display = 'none';
    if (focusEl) focusEl.focus();
}

/** Shows a DOM element that has been hidden and optionally focuses on focusEl. */
function showElement(el, focusEl) {
    el.style.display = 'block';
    if (focusEl) focusEl.focus();
}

/** Determines if a DOM element contains content that cannot be scrolled into view. */
function hasHiddenContent(el) {
    const noscroll = window.getComputedStyle(el).overflowY.includes('hidden');
    return noscroll && el.scrollHeight > el.clientHeight;
}

/** Format a Place Type string by capitalizing and replacing underscores with spaces. */
function formatPlaceType(str) {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized.replace(/_/g, ' ');
}

/** Initializes an array of zeros with the given size. */
function initArray(arraySize) {
    const array = [];
    while (array.length < arraySize) {
        array.push(0);
    }
    return array;
}

/** Assigns star icons to an object given its rating (out of 5). */
function addStarIcons(obj) {
    if (!obj.rating) return;
    const starsOutOfTen = Math.round(2 * obj.rating);
    const fullStars = Math.floor(starsOutOfTen / 2);
    const halfStars = fullStars !== starsOutOfTen / 2 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    // Express stars as arrays to make iterating in Handlebars easy.
    obj.fullStarIcons = initArray(fullStars);
    obj.halfStarIcons = initArray(halfStars);
    obj.emptyStarIcons = initArray(emptyStars);
}

/**
 * Constructs an array of opening hours by day from a PlaceOpeningHours object,
 * where adjacent days of week with the same hours are collapsed into one element.
 */
function parseDaysHours(openingHours) {
    const daysHours = openingHours.weekday_text.map((e) => e.split(/:\s+/))
        .map((e) => ({ 'days': e[0].substr(0, 3), 'hours': e[1] }));

    for (let i = 1; i < daysHours.length; i++) {
        if (daysHours[i - 1].hours === daysHours[i].hours) {
            if (daysHours[i - 1].days.indexOf('-') !== -1) {
                daysHours[i - 1].days =
                    daysHours[i - 1].days.replace(/\w+$/, daysHours[i].days);
            } else {
                daysHours[i - 1].days += ' - ' + daysHours[i].days;
            }
            daysHours.splice(i--, 1);
        }
    }
    return daysHours;
}

/** Number of POIs to show on widget load. */
const ND_NUM_PLACES_INITIAL = 5;

/** Number of additional POIs to show when 'Show More' button is clicked. */
const ND_NUM_PLACES_SHOW_MORE = 5;

/** Maximum number of place photos to show on the details panel. */
const ND_NUM_PLACE_PHOTOS_MAX = 6;

/** Minimum zoom level at which the default map POI pins will be shown. */
const ND_DEFAULT_POI_MIN_ZOOM = 18;

/** Mapping of Place Types to Material Icons used to render custom map markers. */
const ND_MARKER_ICONS_BY_TYPE = {
    // Full list of icons can be found at https://fonts.google.com/icons
    '_default': 'circle',
    'restaurant': 'restaurant',
    'bar': 'local_bar',
    'park': 'park',
    'stadium': 'sports_handball',
    'museum': 'museum',
    'supermarket': 'local_grocery_store',
    'department_store': 'local_mall',
    'shopping_mall': 'local_mall',
    'primary_school': 'school',
    'secondary_school': 'school',
    'laundry': 'local_laundry_service',
    'hospital': 'local_hospital',
    'pharmacy': 'local_pharmacy',
};

/**
 * Defines an instance of the Neighborhood Discovery widget, to be
 * instantiated when the Maps library is loaded.
 */
function NeighborhoodDiscovery(configuration, setNearbyStores, setAddress, setError) {
    const widget = this;
    const widgetEl = document.querySelector('.neighborhood-discovery');

    widget.center = configuration.mapOptions.center;
    // widget.places = configuration.pois || [];

    // Initialize core functionalities -------------------------------------

    initializeMap();
    
    geocodeLatLng(widget.center, setAddress)

    // Call the function to get places around the center
    getPlacesAround();

    // Initializer function definitions ------------------------------------

    /** Initializes the interactive map and adds place markers. */
    function initializeMap() {
        const mapOptions = configuration.mapOptions;
        widget.mapBounds = new google.maps.Circle(
            { center: widget.center, radius: configuration.mapRadius }).getBounds();
        mapOptions.restriction = { latLngBounds: widget.mapBounds };
        mapOptions.mapTypeControlOptions = { position: google.maps.ControlPosition.TOP_RIGHT };
        try {
            widget.map = new google.maps.Map(widgetEl.querySelector('.map'), mapOptions);
        }
        catch (e) {
            setError(e);
            return;
        }
        widget.map.fitBounds(widget.mapBounds, /* padding= */ 0);
        widget.map.addListener('click', (e) => {
            // Check if user clicks on a POI pin from the base map.
            if (e.placeId) {
                e.stop();
                widget.selectPlaceById(e.placeId);
            }
        });
        widget.map.addListener('zoom_changed', () => {
            // Customize map styling to show/hide default POI pins or text based on zoom level.
            const hideDefaultPoiPins = widget.map.getZoom() < ND_DEFAULT_POI_MIN_ZOOM;
            widget.map.setOptions({
                styles: [{
                    featureType: 'poi',
                    elementType: hideDefaultPoiPins ? 'labels' : 'labels.text',
                    stylers: [{ visibility: 'off' }],
                }],
            });
        });

        const markerPath = widgetEl.querySelector('.marker-pin path').getAttribute('d');
        const drawMarker = function (title, position, fillColor, strokeColor, labelText) {
            return new google.maps.Marker({
                title: title,
                position: position,
                map: widget.map,
                icon: {
                    path: markerPath,
                    fillColor: fillColor,
                    fillOpacity: 1,
                    strokeColor: strokeColor,
                    anchor: new google.maps.Point(13, 35),
                    labelOrigin: new google.maps.Point(13, 13),
                },
                label: {
                    text: labelText,
                    color: 'white',
                    fontSize: '16px',
                    fontFamily: 'Material Icons',
                },
            });
        };

        // Add marker for the specified Place object.
        widget.addPlaceMarker = function (place) {
            place.marker = drawMarker(place.name, place.coords, '#EA4335', '#C5221F', place.icon);
            place.marker.addListener('click', () => void widget.selectPlaceById(place.place_id));
        };

        // Fit map to bounds that contain all markers of the specified Place objects.
        widget.updateBounds = function (places) {
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(widget.center);
            for (let place of places) {
                if (DEBUG) console.log(`widget.updateBounds | place.marker:`, place.marker);
                bounds.extend(place.marker.getPosition());
            }
            widget.map.fitBounds(bounds, /* padding= */ 100);
        };

        // Marker used to highlight a place from Autocomplete search.
        widget.selectedPlaceMarker = new google.maps.Marker({ title: 'Point of Interest' });
    }

    /** Initializes Place Details service for the widget. */
    function initializePlaceDetails() {
        const detailsService = new google.maps.places.PlacesService(widget.map);
        const placeIdsToDetails = new Map();  // Create object to hold Place results.

        for (let place of widget.places) {
            placeIdsToDetails.set(place.place_id, place);
            place.fetchedFields = new Set(['place_id']);
        }

        widget.fetchPlaceDetails = function (placeId, fields, callback) {
        if (DEBUG) console.log(`1) fetchPlaceDetails | placeId: ${placeId}, fields:`, fields);
            if (!placeId || !fields) return;
            if (DEBUG) console.log(`2) fetchPlaceDetails | placeId: ${placeId}, fields:`, fields);
            // Check for field existence in Place object.
            let place = placeIdsToDetails.get(placeId);
            if (DEBUG) console.log(`fetchPlaceDetails | place:`, place);
            if (!place) {
                place = { placeId: placeId, fetchedFields: new Set(['place_id']) };
                placeIdsToDetails.set(placeId, place);
            }
            const missingFields = fields.filter((field) => !place.fetchedFields.has(field));
            if (DEBUG) console.log(`fetchPlaceDetails | missingFields:`, missingFields);
            if (missingFields.length === 0) {
                callback(place);
                return;
            }

            const request = { placeId: placeId, fields: missingFields };
            let retryCount = 0;
            const processResult = function (result, status) {
                if (DEBUG) console.log(`1-1) fetchPlaceDetails-processResult | result:`, result, 'status:', status);
                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    // If query limit has been reached, wait before making another call;
                    // Increase wait time of each successive retry with exponential backoff
                    // and terminate after five failed attempts.
                    if (status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT &&
                        retryCount < 5) {
                        const delay = (Math.pow(2, retryCount) + Math.random()) * 500;
                        setTimeout(() => void detailsService.getDetails(request, processResult), delay);
                        retryCount++;
                    }
                    return;
                }

                // Basic details.
                if (result.name) place.name = result.name;
                if (result.geometry) place.coords = result.geometry.location;
                if (result.formatted_address) place.address = result.formatted_address;
                if (result.photos) {
                    place.photos = result.photos.map((photo) => ({
                        urlSmall: photo.getUrl({ maxWidth: 200, maxHeight: 200 }),
                        urlLarge: photo.getUrl({ maxWidth: 1200, maxHeight: 1200 }),
                        attrs: photo.html_attributions,
                    })).slice(0, ND_NUM_PLACE_PHOTOS_MAX);
                }
                if (result.types) {
                    place.type = formatPlaceType(result.types[0]);
                    place.icon = ND_MARKER_ICONS_BY_TYPE['_default'];
                    for (let type of result.types) {
                        if (type in ND_MARKER_ICONS_BY_TYPE) {
                            place.type = formatPlaceType(type);
                            place.icon = ND_MARKER_ICONS_BY_TYPE[type];
                            break;
                        }
                    }
                }
                if (result.url) place.url = result.url;

                // Contact details.
                if (result.website) {
                    place.website = result.website;
                    const url = new URL(place.website);
                    place.websiteDomain = url.hostname;
                }
                if (result.formatted_phone_number) place.phoneNumber = result.formatted_phone_number;
                if (result.opening_hours) place.openingHours = parseDaysHours(result.opening_hours);

                // Review details.
                if (result.rating) {
                    place.rating = result.rating;
                    addStarIcons(place);
                }
                if (result.user_ratings_total) place.numReviews = result.user_ratings_total;
                if (result.price_level) {
                    place.priceLevel = result.price_level;
                    place.dollarSigns = initArray(result.price_level);
                }
                if (result.reviews) {
                    place.reviews = result.reviews;
                    for (let review of place.reviews) {
                        addStarIcons(review);
                    }
                }

                for (let field of missingFields) {
                    place.fetchedFields.add(field);
                }
                callback(place);
                if (DEBUG) console.log(`1-2) fetchPlaceDetails-processResult | RESULTING place:`, place);
            };

            // Use result from Place Autocomplete if available.
            if (widget.placeIdsToAutocompleteResults) {
                const autoCompleteResult = widget.placeIdsToAutocompleteResults.get(placeId);
                if (autoCompleteResult) {
                    processResult(autoCompleteResult, google.maps.places.PlacesServiceStatus.OK);
                    return;
                }
            }
            detailsService.getDetails(request, processResult);
        };
    }

    /** Initializes the side panel that holds curated POI results. */
    function initializeSidePanel() {
        const placesPanelEl = widgetEl.querySelector('.places-panel');
        const detailsPanelEl = widgetEl.querySelector('.details-panel');
        const placeResultsEl = widgetEl.querySelector('.place-results-list');
        const showMoreButtonEl = widgetEl.querySelector('.show-more-button');
        const photoModalEl = widgetEl.querySelector('.photo-modal');

        // const detailsTemplate = Handlebars.compile(
        //     document.getElementById('nd-place-details-tmpl').innerHTML);
        // const resultsTemplate = Handlebars.compile(
        //     document.getElementById('nd-place-results-tmpl').innerHTML);
        const detailsTemplate = Handlebars.compile(detailsTemplateInnerHTML);
        const resultsTemplate = Handlebars.compile(resultsTemplateInnerHTML);

        // Show specified POI photo in a modal.
        const showPhotoModal = function (photo, placeName) {
            const prevFocusEl = document.activeElement;
            const imgEl = photoModalEl.querySelector('img');
            imgEl.src = photo.urlLarge;
            const backButtonEl = photoModalEl.querySelector('.back-button');
            backButtonEl.addEventListener('click', () => {
                hideElement(photoModalEl, prevFocusEl);
                imgEl.src = '';
            });
            photoModalEl.querySelector('.photo-place').innerHTML = placeName;
            photoModalEl.querySelector('.photo-attrs span').innerHTML = photo.attrs;
            const attributionEl = photoModalEl.querySelector('.photo-attrs a');
            if (attributionEl) attributionEl.setAttribute('target', '_blank');
            photoModalEl.addEventListener('click', (e) => {
                if (e.target === photoModalEl) {
                    hideElement(photoModalEl, prevFocusEl);
                    imgEl.src = '';
                }
            });
            showElement(photoModalEl, backButtonEl);
        };

        // Select a place by id and show details.
        let selectedPlaceId;
        widget.selectPlaceById = function (placeId, panToMarker) {
            if (selectedPlaceId === placeId) return;
            selectedPlaceId = placeId;
            const prevFocusEl = document.activeElement;

            const showDetailsPanel = function (place) {
                if (DEBUG) console.log('selectPlaceById() | showDetailsPanel() | place:', place);
                detailsPanelEl.innerHTML = detailsTemplate(place);
                const backButtonEl = detailsPanelEl.querySelector('.back-button');
                backButtonEl.addEventListener('click', () => {
                    hideElement(detailsPanelEl, prevFocusEl);
                    selectedPlaceId = undefined;
                    widget.selectedPlaceMarker.setMap(null);
                });
                detailsPanelEl.querySelectorAll('.photo').forEach((photoEl, i) => {
                    photoEl.addEventListener('click', () => {
                        showPhotoModal(place.photos[i], place.name);
                    });
                });
                showElement(detailsPanelEl, backButtonEl);
                detailsPanelEl.scrollTop = 0;
            };

            const processResult = function (place) {
                if (DEBUG) console.log('selectPlaceById() | processResult() | place:', place);
                if (place.marker) {
                    widget.selectedPlaceMarker.setMap(null);
                } else {
                    widget.selectedPlaceMarker.setPosition(place.coords);
                    widget.selectedPlaceMarker.setMap(widget.map);
                }
                if (panToMarker) {
                    widget.map.panTo(place.coords);
                }
                showDetailsPanel(place);
            };

            widget.fetchPlaceDetails(placeId, [
                'name', 'types', 'geometry.location', 'formatted_address', 'photo', 'url',
                'website', 'formatted_phone_number', 'opening_hours',
                'rating', 'user_ratings_total', 'price_level', 'review',
            ], processResult);
        };

        // Render the specified place objects and append them to the POI list.
        const renderPlaceResults = function (places, startIndex) {
            if (DEBUG) console.log('selectPlaceById() | renderPlaceResults() | places:', places, 'startIndex:', startIndex);
            placeResultsEl.insertAdjacentHTML('beforeend', resultsTemplate({ places: places }));
            placeResultsEl.querySelectorAll('.place-result').forEach((resultEl, i) => {
                const place = places[i - startIndex];
                if (!place) return;
                // Clicking anywhere on the item selects the place.
                // Additionally, create a button element to make this behavior
                // accessible under tab navigation.
                resultEl.addEventListener('click', () => {
                    widget.selectPlaceById(place.place_id, /* panToMarker= */ true);
                });
                resultEl.querySelector('.name').addEventListener('click', (e) => {
                    widget.selectPlaceById(place.place_id, /* panToMarker= */ true);
                    e.stopPropagation();
                });
                resultEl.querySelector('.photo').addEventListener('click', (e) => {
                    showPhotoModal(place.photos[0], place.name);
                    e.stopPropagation();
                });
                widget.addPlaceMarker(place);
            });
        };

        // Index of next Place object to show in the POI list.
        let nextPlaceIndex = 0;

        // Fetch and show basic info for the next N places.
        const showNextPlaces = function (n) {
            const nextPlaces = widget.places.slice(nextPlaceIndex, nextPlaceIndex + n);
            if (DEBUG) console.log(`showNextPlaces() | nextPlaces.length: ${nextPlaces.length} nextPlaces:`, nextPlaces);
            if (nextPlaces.length < 1) {
                hideElement(showMoreButtonEl);
                return;
            }
            showMoreButtonEl.disabled = true;
            // Keep track of the number of Places calls that have not finished.
            let count = nextPlaces.length;
            const processResult = function (place) {
                if (DEBUG) console.log(`>>> processResult | place:`, place);
                count--;
                if (count > 0) return;
                renderPlaceResults(nextPlaces, nextPlaceIndex);
                nextPlaceIndex += n;
                widget.updateBounds(widget.places.slice(0, nextPlaceIndex));
                const hasMorePlacesToShow = nextPlaceIndex < widget.places.length;
                if (hasMorePlacesToShow || hasHiddenContent(placesPanelEl)) {
                    showElement(showMoreButtonEl);
                    showMoreButtonEl.disabled = false;
                } else {
                    hideElement(showMoreButtonEl);
                }
            };
            for (let place of nextPlaces) {
                if (DEBUG) console.log(`>>> let place of nextPlaces | place:`, place);
                widget.fetchPlaceDetails(place.place_id, [
                    'name', 'types', 'geometry.location',
                    'photo',
                    'rating', 'user_ratings_total', 'price_level',
                ], processResult);
            }
        };

        showNextPlaces(ND_NUM_PLACES_INITIAL);

        showMoreButtonEl.addEventListener('click', () => {
            placesPanelEl.classList.remove('no-scroll');
            showMoreButtonEl.classList.remove('sticky');
            showNextPlaces(ND_NUM_PLACES_SHOW_MORE);
        });
    }

    /** Initializes Search Input for the widget. */
    function initializeSearchInput() {
        const searchInputEl = widgetEl.querySelector('.place-search-input');
        widget.placeIdsToAutocompleteResults = new Map();

        // Set up Autocomplete on the search input.
        const autocomplete = new google.maps.places.Autocomplete(searchInputEl, {
            types: ['establishment'],
            fields: [
                'place_id', 'name', 'types', 'geometry.location', 'formatted_address', 'photo', 'url',
                'website', 'formatted_phone_number', 'opening_hours',
                'rating', 'user_ratings_total', 'price_level', 'review',
            ],
            bounds: widget.mapBounds,
            strictBounds: true,
        });
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            widget.placeIdsToAutocompleteResults.set(place.place_id, place);
            widget.selectPlaceById(place.place_id, /* panToMarker= */ true);
            searchInputEl.value = '';
        });
    }

    // ----------

    /** Create a maker in the Map **/
    const createMarker = (place) => {
        // Documentation Reference: Markers
        // https://developers.google.com/maps/documentation/javascript/markers
        const icon = ND_MARKER_ICONS_BY_TYPE[place.types[0]] || ND_MARKER_ICONS_BY_TYPE['_default'];
        const marker = new google.maps.Marker({
            position: place.geometry.location,
            map: widget.map,
            title: place.name,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 10, // Size of the icon
                fillColor: icon,
                fillOpacity: 0.8,
                strokeWeight: 2,
                strokeColor: 'white',
            },
        });
        marker.addListener('click', () => {
            widget.map.setCenter(marker.getPosition());
            // Optionally, you can add more actions when a marker is clicked
        });
        return marker;
    };

    /** Fetch Nearby Places **/
    function getPlacesAround() {
        // Documentation Reference: Nearby Search (New)
        // https://developers.google.com/maps/documentation/places/web-service/nearby-search
        if (DEBUG) console.log('Searching Places arround...');
        const service = new google.maps.places.PlacesService(widget.map);
        const request = {
            location: widget.center,
            // radius: '1000', // Search within a 1 km radius
            radius: configuration.mapRadius,
            types: configuration.businessTypeFilter,
        };

        service.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                if (DEBUG) console.log('>> Places found:', results);

                widget.places = results;
                let marker;
                for (var i = 0; i < widget.places.length; i++) {
                    if (!widget.places[i].rating) {
                        widget.places[i].rating = 0;
                    }
                    marker = createMarker(results[i]);
                    if (!widget.places[i].marker) {
                        widget.places[i].marker = marker;
                    }
                }
                
                setNearbyStores(widget.places);
                
                // Optionally, process the results, e.g., display them on the map or list them in the UI.
                initializePlaceDetails();
                initializeSidePanel();

                // Initialize additional capabilities ----------------------------------
                initializeSearchInput();
            } else {
                console.error('No places found:', status);
            }
        });
        if (DEBUG) console.log('Waiting for searching places response...');
    };

    // ------

    /*** Get address related to the current llocation ***/
    function geocodeLatLng(currentLocation, setAddress) {
        // Documentation Reference: Reverse Geocoding
        // https://developers.google.com/maps/documentation/javascript/examples/geocoding-reverse#maps_geocoding_reverse-html
        const map = widget.map;
        if (!map) return;
    
        const geocoder = new google.maps.Geocoder();
        const infowindow = new google.maps.InfoWindow();
        const latlng = {
          lat: parseFloat(currentLocation.lat),
          lng: parseFloat(currentLocation.lng),
        };
      
        geocoder
          .geocode({ location: latlng })
          .then((response) => {
            if (response.results[0]) {
              map.setZoom(11);
      
              const marker = new google.maps.Marker({
                position: latlng,
                map: map,
              });
      
              setAddress(response.results[0].formatted_address);
              infowindow.setContent(response.results[0].formatted_address);
              infowindow.open(map, marker);
            } else {
              window.alert("No results found");
            }
          })
          .catch((e) => window.alert("Geocoder failed due to: " + e));
      }
}
// ------ Google Maps example JS scripts

const getConfiguration = (currentLocation) => {
    let configuration = CONFIGURATION;
    let mapOptions = configuration.mapOptions;
    mapOptions.center.lat = currentLocation.lat;
    mapOptions.center.lng = currentLocation.lng;
    // mapOptions.center = new google.maps.LatLng(currentLocation.lat, currentLocation.lng)
    const mapBounds = new google.maps.Circle({ center: mapOptions.center, radius: CONFIGURATION.mapRadius }).getBounds();
    mapOptions.restriction = { latLngBounds: mapBounds };
    mapOptions.mapTypeControlOptions = { position: google.maps.ControlPosition.TOP_RIGHT };
    // const initializedMap = new google.maps.Map(mapRef.current, mapOptions);
    // setMap(initializedMap);
    // setPlaces(CONFIGURATION.pois); // Simplified for this example
    configuration.mapOptions = mapOptions
    return configuration;
};

export const getGmapsConfiguration = () => {
    return gMapsConfiguration;
}

export const loadGoogleMapsScript = (apiKey, callback) => {
    if (typeof google === 'object' && typeof google.maps === 'object') {
        if (DEBUG) console.log('>> Google Maps API ALREADY loaded [1]...');
        callback(); // Google Maps is already loaded
    } else {
        if (DEBUG) console.log('Loading Google Maps API...');
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        // https://developers.google.com/maps/documentation/javascript/libraries
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&libraries=places,geocoding`;
        document.head.appendChild(script);
        window.initMap = function () {
            callback();
            // The next line must be commentet to avoid error
            // "Uncaught (in promise) InvalidValueError: initMap is not a function"
            // window.initMap = null; // Clean-up
        };
        if (DEBUG) console.log('Google Maps API loaded');
    }
}

export async function initMap(setNearbyStores, setAddress, setError) {
    navigator.geolocation.getCurrentPosition((position) => {
        const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        gMapsConfiguration = getConfiguration(currentLocation);
        new NeighborhoodDiscovery(gMapsConfiguration, setNearbyStores, setAddress, setError);
    });
}
